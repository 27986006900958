<template>
	
	<div class="chart-bar" :class="classes"><div>

		<div class="chart-bar_label" v-if="label">{{ label }}</div>

		<div class="bar">
			<div class="chart-bar_bg" :style="'width:' + bar_width + '%;'"></div>
		</div>

		<div class="chart-bar_value" v-if="value_text">{{ value_text }}</div>

	</div></div>

</template>

<script>
export default {

	name: 'chart-bars',

	props	: [ 'label', 'value', 'base_width', 'value_text', 'classes' ],

	data	: function() { return {

		bar_width	: 0, 

	}}, 

	methods	: {

		init() {

			if ( this.base_width && this.value ) {
				this.bar_width	= ( ( this.value / this.base_width ) * 100 ).toFixed(0)
			}

		},

	},

	watch	: {

		base_width	: function() {
			this.init()
		},

		value	: function() {
			this.init()
		},

	},

	mounted	: function() {
		this.init()
	},

}
</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';

	.chart-bar {

		position: relative;
		flex: 100%;
		max-width: 100%;
		padding: 0;

		& > div {
			width: 100%;
			max-width: 300px;
		}

		.chart-bar_bg {
			display: block;
			height: 15px;
			transition: width 600ms $ease-inout-back 500ms;
			background-color: #000;
		}

		.chart-bar_value {
			position: relative;
			width: 100%;
			z-index: 3;
		}
	
	}

</style>